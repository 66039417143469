import React from 'react';

function Projects() {
  return (
    <div id="projects">
      <div className="container" style={{minHeight: '100vh'}}>
        <div className="row">
          projects – do something here...
        </div>
      </div>
    </div>
  );
}

export default Projects;
